import React, { useEffect, useRef, useState } from "react";
import { Chip, Icon, Typography, Grid, Box, Alert, TextField, Paper } from "@mui/material";
import {
  getAmountDuePayment,
  getDepositedTotal,
  getPolicyOffersStatusCSCplusGO,
  getPolicyStatus,
  getPolicyStatusCSCplusGO,
  getSumTotal,
  installmentsTxt,
  productIcon
} from "../../../utils/helpers";
import StatusBadge from "./StatusBadge";
import _get from "lodash.get";
import moment from "moment";
import { getPoliciesNew } from "../../../services/PoliciesService";
import DetailPanel from "../../DetailPanel";
import MaterialTable from "material-table";
import MessageIcon from "@mui/icons-material/MessageOutlined";
import InstallmentIcon from "@mui/icons-material/Update";
import Clear from "@mui/icons-material/Clear";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { connect } from "react-redux";
import { rolesMatched } from "../../PrivateRoute/PrivateRoute";
import { checkCardIsExpired } from "../../../utils/functions";
import { warning, danger, success, gray, primary, info, black, white, grayLight, grayBorder } from "../../../utils/colors";
import UtmAndSource from "./UtmAndSource";
import FromTo from "../../FromTo";
import {
  addThirtyDays,
  subtractThirtyDays,
  subtractFifteenDays,
  addSixtyDays,
  subtractSixtyDays,
  addHundredAndTwentyDays,
  subtractHundredAndTwentyDays
} from "../../../utils/momentConstants";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/Search";
import "./policies.table.new.sass";
import VariantStatus from "../../VariantStatus";
import { useLocation } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

const PoliciesTableNew = props => {
  const [fastDate, setFastDate] = useState(props?.fastDate);
  const [searchText, setSearchText] = useState("");
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const primacy = props?.primacy;
  const variant = props?.variant;
  const subVariant = props?.subVariant;
  const tableRef = useRef();
  let firstQuery = true;
  const hideActions = props?.hideActions || false;
  const fastDatesList = props?.fastDatesList || false;
  const setSelectedTab = props?.setSelectedTab;
  const innerTabs = props?.innerTabs;
  const location = useLocation();
  const currentRoute = _get(location, "pathname", "");
  const currentProduct = (currentRoute?.replace(/\//g, "") || "ALL").toUpperCase();

  const tabsConfig = {
    GO: {
      queries: "За Издаване",
      policies: "Полици",
      installments: "Вноски"
    },
    CSC: {
      queries: "Заявки за оферти",
      // policies: "Полици",
      policies: "Полици",
      installments: "Вноски"
    },
    ["CSC+GO"]: {
      queries: "За Издаване",
      offers: "Заявки за оферти",
      policies: "Полици"
    },
    TRA: {
      policies: "Полици"
    },
    PRP: {
      policies: "Полици",
      installments: "Вноски"
    },
    MTN: {
      policies: "Полици"
    },
    EXT: {
      policies: "Полици"
    },
    HLT: {
      policies: "Полици",
      installments: "Вноски"
    },
    MED: {
      policies: "Полици",
      installments: "Вноски"
    },
    OFF: {
      policies: "Полици",
      installments: "Вноски",
      create: {
        label: "Създай полица",
        icon: <AddIcon />,
        action: () => props?.handleAction({ productId: "OFF" }, { hasModal: true, id: "issuePolicyWizard" }, () => refreshData(_get(tableRef, "current.state.query.current"), true))
      }
    },
    LFE: {
      queries: "За Издаване",
      policies: "Полици",
      installments: "Вноски"
    },
    ALL: {
      policies: "Полици",
      installments: "Вноски"
    },
    POLICIES: {
      policies: "Полици",
      installments: "Вноски"
    }
  };

  const refreshData = (obj, ignoreFirstQueryCheck = false) => {
    const query = _get(tableRef, "current.state.query", {});

    if (!obj) {
      obj = { from: "", to: "" };
    }
    const { from, to } = obj;
    query.current = { ...query.current, from, to };
    (!firstQuery || ignoreFirstQueryCheck) && tableRef.current && tableRef.current.onQueryChange(query);
    firstQuery = false;
  };

  // const shouldComponentUpdate = nextProps => {
  //   let shouldNotUpdate = false;
  //   return !shouldNotUpdate;
  // };
  const handleChange = selectedTab => {
    setSelectedTab(selectedTab);
    sessionStorage.setItem("selectedTab", selectedTab);
  };
  const handleFastDatesClick = filter => {
    let from = "";
    let to = "";
    // let selectedFromDate = "";
    // let selectedToDate = "";
    switch (filter) {
      case "from15to30":
        from = subtractFifteenDays;
        to = addThirtyDays;
        // selectedFromDate = subtractFifteenDays;
        // selectedToDate = addThirtyDays;
        break;
      case "from30to30":
        from = subtractThirtyDays;
        to = addThirtyDays;
        // selectedFromDate = subtractThirtyDays;
        // selectedToDate = addThirtyDays;
        break;
      case "from60to60":
        from = subtractSixtyDays;
        to = addSixtyDays;
        // selectedFromDate = subtractSixtyDays;
        // selectedToDate = addSixtyDays;
        break;
      case "from120to120":
        from = subtractHundredAndTwentyDays;
        to = addHundredAndTwentyDays;
        // selectedFromDate = subtractSixtyDays;
        // selectedToDate = addSixtyDays;
        break;
    }
    setFastDate(filter);
    setFrom(from);
    setTo(to);
    refreshData({ from: from, to: to });
  };

  const user = _get(props, "auth.user");
  const searchQuery = _get(props, "searchQuery", "");
  const title = _get(props, "title");
  const productLookup = _get(props, "productLookup", {});
  const productDefaultFilter = _get(props, "productDefaultFilter", []);
  const productNameKeyLookup = _get(props, "productNameKeyLookup", {});
  const productNameKeyDefaultFilter = _get(props, "productNameKeyDefaultFilter", {});
  const statusLookup = _get(props, "statusLookup", {});
  const statusDefaultFilter = _get(props, "statusDefaultFilter", []);
  const offerLookup = _get(props, "offerLookup", null);
  const offerDefaultFilter = _get(props, "offerDefaultFilter", {});
  const offerstatusLookup = _get(props, "offerstatusLookup", null);
  const statuscombinedLookup = _get(props, "combinedstatusLookup", null);
  const offerstatusDefaultFilter = _get(props, "offerstatusDefaultFilter", {});
  const statuscombinedDefaultFilter = _get(props, "statuscombinedDefaultFilter", {});
  const offerstatuscombinedDefaultFilter = _get(props, "offerstatuscombinedDefaultFilter", {});
  const insurerIdLookup = _get(props, "insurerIdLookup", {});
  const insurerIdDefaultFilter = _get(props, "insurerIdDefaultFilter", {});
  const variantLookup = _get(props, "variantLookup", {});
  const variantDefaultFilter = _get(props, "variantDefaultFilter", {});
  const fromDays = _get(props, "fromDays");
  const toDays = _get(props, "toDays");
  const columnsNames = _get(props, "listOfProps", []);

  useEffect(() => {
    setSearchText(searchQuery);
  }, []);
  const { roles, utmSources } = user || {};
  const columnsAll = [
    {
      title: "Продукт",
      field: "productId",
      sorting: false,
      filtering: ["/LFE", "/ALL"].includes(location.pathname) ? true : false,
      lookup: productLookup,
      filterPlaceholder: "",
      defaultFilter: productDefaultFilter,
      render: policy => {
        let policyName = policy?.productName;
        if (["CI"].includes(policy?.productId)) {
          policyName = "Групов живот";
        }
        if (["LFI"].includes(policy?.productId)) {
          policyName = "Инвестиционен живот";
        }
        if (["OFF"].includes(policy?.productId)) {
          policyName = policy?.subProduct;
        }
        return (
          <>
            {productIcon(currentProduct === "CSC+GO" ? currentProduct : policy.productId, policyName)}
            <UtmAndSource utmSource={policy?.utmSource} source={policy?.source} />
          </>
        );
      },
      filterCellStyle: {
        maxWidth: "60px",
        position: "relative",
        top: "-52px",
        zIndex: 10
      },
      headerStyle: { maxWidth: "60px" },
      bodyStyle: { maxWidth: "60px" },
      cellStyle: { maxWidth: "60px" }
    },
    {
      title: "Име",
      field: "productNameKey",
      minWidth: 60,
      maxWidth: 60,
      sorting: false,
      filtering: true,
      filterCellStyle: {
        maxWidth: "60px",
        position: "relative",
        top: "-52px",
        zIndex: 10
      },
      lookup: productNameKeyLookup,
      defaultFilter: productNameKeyDefaultFilter,
      headerStyle: { maxWidth: "60px" },
      bodyStyle: { maxWidth: "60px" },
      cellStyle: { maxWidth: "60px" },
      render: () => {
        return <span></span>;
      }
    },
    {
      title: "Застраховател",
      field: "insurerId",
      sorting: false,
      filtering: true,
      minWidth: 150,
      maxWidth: 150,
      filterPlaceholder: "",
      filterCellStyle: {
        maxWidth: "60px",
        position: "relative",
        top: "-52px",
        zIndex: 10
      },
      lookup: insurerIdLookup,
      defaultFilter: insurerIdDefaultFilter,
      headerStyle: { maxWidth: "150px" },
      bodyStyle: { maxWidth: "150px" },
      cellStyle: { maxWidth: "150px" },
      render: policy => {
        return <p className="gray-text size-6 has-text-centered bold">{policy?.policyData?.insurerName}</p>;
      }
    },
    {
      title: "Статус",
      field: "status",
      lookup: statusLookup,
      defaultFilter: statusDefaultFilter,
      sorting: false,
      filtering: false,

      render: policy => {
        const { isRenewal, isRenewed } = policy;
        const productId = _get(policy, "productId");
        const isOffer = (productId === "CSC" && _get(policy, "policyData.cscIsOffer")) || (productId === "GO" && _get(policy, "isOffer"));
        const isNotRegisteredVehicle = _get(policy, "policyData.isNotRegisteredVehicle", false);

        return (
          <>
            {getPolicyStatus(policy, variant, subVariant)}
            {isOffer && (
              <div className="vertical-align" style={{ marginTop: "5px" }}>
                <ArticleOutlinedIcon sx={{ marginRight: "4px", color: grayBorder }} fontSize="inherit" />
                <span className="size-6" style={{ color: primary, display: "block" }}>
                  оферта
                </span>
              </div>
            )}
            {isRenewal && <StatusBadge isRenewal={isRenewal} />}
            {isNotRegisteredVehicle && <span className="backoffice-status-text danger inline-block">нерегистриран</span>}
            {policy?.comment && <MessageIcon htmlColor={primary} fontSize="inherit" style={{ display: "inline-block", marginTop: 5 }} />}
          </>
        );
      }
    },
    {
      title: "Статус",
      field: "variant",
      sorting: false,
      filtering: false,
      lookup: variantLookup,
      defaultFilter: variantDefaultFilter,
      render: policy => {
        const { variant, productId } = policy;
        let variants = Array.isArray(variant) ? variant : [variant];
        let daysOverdue = "";
        let daysOverdueHtml = null;
        // ако contractDueDate е по-малка от dueDate показваме просрочие
        if (subVariant === "overdue") {
          variants = variants.filter(item => item !== "process");

          daysOverdue = _get(policy, "contractDueDate") ? moment().diff(moment(_get(policy, "contractDueDate")), "days") : "";
          daysOverdueHtml =
            daysOverdue > 0 ? (
              <b className="font-small" style={{ color: daysOverdue >= 9 ? danger : warning, display: "block", minWidth: 100 }}>
                просрочие: {daysOverdue} дни
              </b>
            ) : (
              <></>
            );
        }
        return (
          <>
            {!["all", "process", "overdue", "cancelled", "rejected"].includes(subVariant) && <VariantStatus status={subVariant} productId={productId} />}
            {["all", "process", "overdue", "cancelled", "rejected"].includes(subVariant) && variants.map(v => <VariantStatus key={v} status={v} productId={productId} />)}
            {daysOverdueHtml}
          </>
        );
      }
    },
    {
      title: "Заявки",
      sorting: false,
      hidden: true, // todo това е важно, колоната не се показва, но се извършва филтриране по нея за това трябва да я има
      field: "offerStatus",
      lookup: offerstatusLookup,
      filtering: !!offerstatusLookup,
      defaultFilter: offerstatusDefaultFilter,
      render: policy => {
        return <span>{policy.policyId} </span>;
      }
    },
    {
      title: "Статус",
      sorting: false,
      hidden: variant === "offers" && ["offers", "confirmed"].includes(subVariant), // todo това е важно, колоната не се показва, но се извършва филтриране по нея за това трябва да я има
      field: "statusCombined",
      lookup: statuscombinedLookup,
      filtering: !!statuscombinedLookup,
      defaultFilter: statuscombinedDefaultFilter,
      render: policy => {
        const productId = _get(policy, "productId");
        const isOffer = productId === "CSC" && _get(policy, "policyData.cscIsOffer");
        return (
          <>
            {getPolicyStatusCSCplusGO(policy)}
            {isOffer && (
              <div className="vertical-align" style={{ marginTop: "5px" }}>
                <ArticleOutlinedIcon sx={{ marginRight: "4px", color: grayBorder }} fontSize="inherit" />
                <span className="size-6" style={{ color: primary, display: "block" }}>
                  оферта
                </span>
              </div>
            )}
          </>
        );
      }
    },
    {
      title: "Статус",
      sorting: false,
      hidden: false,
      field: "offerStatusCombined",
      filtering: false,
      defaultFilter: offerstatuscombinedDefaultFilter,
      render: policy => {
        return getPolicyOffersStatusCSCplusGO(policy);
      }
    },
    {
      title: ["LFE"].includes(currentProduct) ? "№ на Полица/Заявление" : "№ на Полица",
      sorting: false,
      field: "offer",
      filtering: !!offerLookup,
      lookup: offerLookup,
      defaultFilter: offerDefaultFilter,
      render: policy => {
        const eik = _get(policy, "policyData.eik");
        const personType = _get(policy, "policyData.personType");
        const productId = _get(policy, "productId");
        const vinNumber = _get(policy, "policyData.vinNumber");
        const isNotRegisteredVehicle = _get(policy, "policyData.isNotRegisteredVehicle", false);

        let carNo = "";
        if (["GO", "CSC"].includes(productId)) {
          carNo = policy?.policyData?.savedCar?.carNo;
          if (productId === "GO" && _get(policy, "policyData.goRegNumber")) {
            carNo = _get(policy, "policyData.goRegNumber");
          }
          if (productId === "CSC" && _get(policy, "policyData.cscRegNumber")) {
            carNo = _get(policy, "policyData.cscRegNumber");
          }
        }
        let orderId = _get(policy, "policyData.orderId");
        return (
          <div>
            {policy?.policyId && <p className="size-6">{policy?.policyId}</p>}
            {currentProduct === "CSC+GO" && <p className="size-6">{policy?.goPolicy?.policyId}</p>}
            {!!carNo && <p className="bold size-6">{carNo}</p>}
            {!!isNotRegisteredVehicle && <p className="bold size-6">{vinNumber}</p>}
            {!!orderId && <p className="bold size-6">{orderId.toString()}</p>}
            {["GO", "OFF"].includes(productId) && personType === 1 && eik && (
              <span className="size-7" style={{ color: info, display: "block" }}>
                фирма
              </span>
            )}

            {productId === "GO" && ["3047", "500005", "500006", "500016", "500026", "500027", "500038"].includes(_get(policy, "policyData.savedCar.vehicleData.colorId")) && (
              <span
                className="font-small"
                style={{
                  color: black,
                  display: "inline-block",
                  padding: "0 3px",
                  backgroundColor: warning,
                  textAlign: "center",
                  borderRadius: "5px"
                }}
              >
                може би такси
              </span>
            )}
            {productId === "GO" && _get(policy, "policyData.goPolicyNumber") && (
              <span className="font-small vertical-align">
                <InstallmentIcon htmlColor={primary} fontSize="small" style={{ display: "inline-block" }} />
                вноска
              </span>
            )}
          </div>
        );
      }
    },
    {
      title: "Титуляр",
      filtering: false,
      sorting: false,
      field: "name",
      render: policy => {
        const titularFirstName = _get(policy, "policyData.titularfirstName");
        const titularMiddleName = _get(policy, "policyData.titularmiddleName");
        const titularLastName = _get(policy, "policyData.titularlastName");
        const firstName = _get(policy, "policyData.firstName");
        const middleName = _get(policy, "policyData.middleName");
        const lastName = _get(policy, "policyData.lastName");
        const name = _get(policy, "name");
        const isBlacklisted = _get(policy, "policyData.isBlacklisted");
        const companyName = _get(policy, "policyData.companyName");

        return (
          <p style={{ margin: 0, fontSize: 10 }}>
            {companyName && <span className="size-6 block">{companyName}</span>}
            {policy.productId === "OFF" ? (
              <span className="size-6">{name}</span>
            ) : (
              <span className="size-6">{titularFirstName ? `${titularFirstName} ${titularMiddleName} ${titularLastName}` : `${firstName} ${middleName} ${lastName}`}</span>
            )}
            <br />
            <b className="size-6">{policy.phone}</b> <br />
            <span className="size-6">{policy.email}</span>
            {isBlacklisted && (
              <>
                <span className="font-small vertical-align" style={{ color: danger }}>
                  <Icon style={{ fontSize: "0.75rem" }}>check</Icon> incorrect payer
                </span>
              </>
            )}
          </p>
        );
      }
    },
    {
      title: "Дата на падеж",
      field: "contractDueDate",
      sorting: false,
      render: policy => {
        const contractDueDate = _get(policy, "contractDueDate");
        return (
          <>
            <p className="size-6 has-text-centered">{contractDueDate ? moment(contractDueDate).format("DD-MM-YYYY") : "-"}</p>
          </>
        );
      },
      filtering: false
    },
    {
      title: "Вноски",
      field: "paymentId",
      sorting: false,
      render: policy => {
        const installments = _get(policy, "installments");
        const paymentId = _get(policy, "paymentId");
        return (
          <>
            <b className="size-6">{installments}</b>
            <br />
            <span className="size-6">{installmentsTxt(paymentId)}</span>
          </>
        );
      },
      filtering: false
    },
    {
      title: "Дата на заявката",
      field: "offerDate",
      minWidth: 150,
      maxWidth: 150,
      filtering: false,
      sorting: true,
      render: policy => {
        if (policy?.offerDate) {
          return (
            <p>
              <b className="size-6">{moment(policy.offerDate).locale("bg").format("DD MMM YYYY HH:mm")}</b>
            </p>
          );
        }
        return "-";
      }
    },
    {
      title: "Дата/Период",
      field: "period",
      filtering: false,
      sorting: currentProduct === "GO" && variant === "queries",
      render: policy => {
        if (policy?.policyData?.validFrom && policy?.policyData?.validTo) {
          return (
            <p>
              <b className="size-6">{moment(policy.contractDate).locale("bg").format("ddd, DD MMM YYYY HH:mm")}</b>
              <br />
              <span className="size-6">
                от <b>{moment(policy.policyData.validFrom).locale("bg").format("DD MMM YYYY")}</b>{" "}
              </span>
              <br />
              <span className="size-6">
                до <b>{moment(policy.policyData.validTo).locale("bg").format("DD MMM YYYY")}</b>
              </span>
            </p>
          );
        }
        return "-";
      }
    },
    {
      title: "Дата на плащане/издаване",
      field: "dueDate",
      sorting: false,
      render: policy => {
        const dueDate = _get(policy, "dueDate");
        return (
          <p className="size-6 has-text-centered">
            {dueDate ? moment(dueDate).format("DD-MM-YYYY") : "-"} <br /> <br />
          </p>
        );
      },
      filtering: false
    },
    {
      title: "Период",
      field: "period2",
      sorting: false,
      render: policy => {
        const { isPending, validFrom, validTo } = policy;
        if (validTo && validFrom && !isPending) {
          return (
            <p className="font-small" style={{ minWidth: "80px", margin: 0 }}>
              от <b>{moment(validFrom).locale("bg").format("DD MMM YYYY")} </b>
              <br />
              до <b>{moment(validTo).locale("bg").format("DD MMM YYYY")}</b>
            </p>
          );
        }
        return "-";
      },
      filtering: false
    },
    {
      title: "Статус клиент",
      field: "customerStatus",
      sorting: false,
      render: policy => {
        const customerConfirmation = _get(policy, "customerConfirmation");
        return (
          <p className="font-small" style={{ textAlign: "center" }}>
            {customerConfirmation !== undefined ? <>{customerConfirmation ? "потвърдена" : "отказана"}</> : <> - </>}
          </p>
        );
      },
      filtering: false
    },
    {
      title: "Статус backoffice",
      field: "backofficeStatus",
      sorting: false,
      render: policy => {
        const backofficeStatus = _get(policy, "backofficeStatus", "");
        return (
          <>
            <p className="font-small" style={{ textAlign: "center" }}>
              {backofficeStatus || "-"}
            </p>
          </>
        );
      },
      filtering: false
    },
    {
      title: "Обща премия",
      field: "sumTotal",
      filtering: false,
      sorting: false,
      render: policy => {
        return getSumTotal(policy, currentProduct);
      }
    },
    {
      title: "Вноски",
      minWidth: 50,
      maxWidth: 50,
      field: "numberOfInstallments",
      sorting: false,
      render: policy => {
        const installments = _get(policy, "installments");
        return (
          <>
            <p className="has-text-centered bold size-5">{installments}</p>
          </>
        );
      },
      filtering: false
    },
    {
      title: "Внесена премия",
      field: "depositedTotal",
      filtering: false,
      sorting: false,
      render: policy => {
        const cardNumber = _get(policy, "paymentData.CARD_NUMBER");
        const isVisa = cardNumber && cardNumber.startsWith(4);
        return (
          <div className="vertical-align space-between">
            <div>{getDepositedTotal(policy, currentProduct)}</div>
            {isVisa && (
              <div>
                <Chip
                  size="small"
                  label={<Typography style={{ fontSize: "0.7rem" }}>visa</Typography>}
                  style={{
                    background: primary,
                    color: "white",
                    float: "none"
                  }}
                />
              </div>
            )}
          </div>
        );
      }
    },
    {
      title: "Източник",
      sorting: false,
      field: "source",
      cellStyle: {
        maxWidth: "20px"
      },
      headerStyle: {
        maxWidth: "20px"
      },
      lookup: { web: "web", android: "android", ios: "ios", offline: "offline" },
      render: policy => {
        const utmSource = _get(policy, "utmSource");
        return <UtmAndSource utmSource={utmSource} source={policy.source} />;
      }
    },
    {
      title: "Цена на вноска",
      field: "amountDue",
      sorting: false,
      render: policy => getAmountDuePayment(policy),
      filtering: false
    }
  ];

  const columns = columnsAll.filter(column => {
    //return true;
    return columnsNames.indexOf(column.field) !== -1;
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Stack direction="row" spacing={2}>
            {Object.keys(tabsConfig[currentProduct] || {}).map(tabKey => {
              const tabConfig = tabsConfig[currentProduct][tabKey];

              if (tabConfig.label) {
                return (
                  <Button
                    key={tabKey}
                    className={`button-tab ${variant === tabKey ? "selected" : ""}`}
                    variant="contained"
                    endIcon={<AddIcon />}
                    onClick={() => tabConfig.action()}
                  >
                    {tabConfig.label}
                  </Button>
                );
              }

              return (
                <Button key={tabKey} className={`button-tab ${variant === tabKey ? "selected" : ""}`} variant="contained" onClick={() => handleChange(tabKey)}>
                  {tabsConfig[currentProduct][tabKey]}
                </Button>
              );
            })}
          </Stack>
        </Grid>
        <Grid item xs={4} container justifyContent="flex-end">
          <TextField
            style={{ width: 350 }}
            onChange={e => {
              const value = e?.target?.value;
              setSearchText(value);
              // tableRef?.current?.onSearchChangeDebounce(value);
            }}
            onKeyDown={e => {
              if (e.key === "Enter") {
                if (searchText?.trim()) window.open(`${location?.pathname}?query=${searchText}`, "_blank");
              }
            }}
            variant="outlined"
            size="small"
            value={searchText}
            placeholder="Търсене"
            InputProps={{
              sx: { backgroundColor: white, pt: "1px", pb: "1px" },
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Clear
                    htmlColor={primary}
                    fontSize="small"
                    onClick={() => {
                      setSearchText("");
                      // tableRef?.current?.onSearchChangeDebounce("");
                    }}
                  />
                </InputAdornment>
              )
            }}
          />
          <Button
            onClick={() => {
              window.open(`${location?.pathname}?query=${searchText}`, "_blank");
            }}
            variant="contained"
            size="small"
            className="size-6"
            sx={{ ml: 2 }}
            style={{
              maxHeight: 42
            }}
          >
            Търси
          </Button>
        </Grid>
      </Grid>
      <Grid direction="row" ml={2} mt={4} mb={4} spacing={1} container>
        <Grid item xs={8}></Grid>
        {fastDatesList && (
          <Grid item xs={3}>
            <Box mb={2} xs={4}>
              {fastDatesList.map(el => (
                <Chip
                  key={el.key}
                  onClick={() => handleFastDatesClick(el.key)}
                  size="small"
                  label={
                    <div
                      style={{
                        fontSize: "0.7rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      {el.label}
                    </div>
                  }
                  style={{
                    background: fastDate === el.key ? success : gray,
                    color: "white",
                    float: "none",
                    margin: 2
                  }}
                />
              ))}
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid className="tableNew">
        <MaterialTable
          elevation="0"
          title={title}
          tableRef={tableRef}
          columns={columns}
          data={query =>
            new Promise(resolve => {
              const type = ["expiring", "renewed", "informed"].includes(subVariant) && primacy === "policyPrimacy" ? "expiring" : primacy;
              getPoliciesNew(query, utmSources, roles, type, query.current.from, query.current.to, variant, subVariant).then(result => {
                resolve({
                  data: _get(result, "data.records"),
                  page: _get(result, "data.page") ? _get(result, "data.page") : 0,
                  totalCount: _get(result, "data.count") ? _get(result, "data.count") : 0
                });
              });
            })
          }
          actions={[
            {
              icon: "refresh",
              tooltip: "Refresh Data",
              isFreeAction: true,
              onClick: () => {
                refreshData();
                return true;
              }
            }
          ]}
          options={{
            sorting: true, //true
            filtering: true,
            filterRowStyle: {
              borderBottom: "none"
            },
            search: rolesMatched(["admin", "employee"], roles), //true
            searchText: searchQuery,
            pageSize: 50,
            pageSizeOptions: [20, 50, 100, 200, 500],
            padding: "dense",
            debounceInterval: 0,
            emptyRowsWhenPaging: false,
            tableLayout: "auto", // set tableLayout to fixed
            headerStyle: {
              padding: "6px 2px 20px 2px"
            },
            rowStyle: {
              overflowWrap: "normal"
            }
          }}
          localization={{
            pagination: {
              labelDisplayedRows: "{from}-{to} от {count}",
              labelRowsSelect: "реда"
            },
            toolbar: {
              nRowsSelected: "{0} row(s) selected",
              searchTooltip: "Търси",
              searchPlaceholder: "Търси"
            },
            body: {
              emptyDataSourceMessage: (
                <Alert severity="info">
                  <b>Не са открити съответстващи записи.</b>
                </Alert>
              ),
              filterRow: {
                filterTooltip: "Филтър"
              }
            }
          }}
          detailPanel={rowData => {
            return rolesMatched(["admin", "employee"], roles) ? (
              <>
                <DetailPanel
                  hideActions={hideActions}
                  handleAction={props.handleAction}
                  policy={rowData}
                  refreshData={() => refreshData(_get(tableRef, "current.state.query.current"), true)}
                  type={["installments"].includes(variant) ? "installments" : "policies"}
                  variant={variant}
                  subVariant={subVariant}
                  currentProduct={currentProduct}
                  showSubProductTitle={currentProduct === "CSC+GO"}
                />
                {currentProduct === "CSC+GO" && (
                  <DetailPanel
                    hideActions={hideActions}
                    handleAction={props.handleAction}
                    policy={rowData}
                    refreshData={() => refreshData(_get(tableRef, "current.state.query.current"), true)}
                    type={["installments"].includes(variant) ? "installments" : "policies"}
                    variant={variant}
                    subVariant={subVariant}
                    currentProduct={currentProduct}
                    showSubProductTitle={currentProduct === "CSC+GO"}
                    showSecondaryProduct
                  />
                )}
              </>
            ) : null;
          }}
          components={{
            Toolbar: () => (
              <div style={{ display: "flex", justifyContent: "space-between", backgroundColor: grayLight }}>
                <div style={{ backgroundColor: grayLight, minWidth: 960 }}>{innerTabs}</div>
                <div style={{ backgroundColor: grayLight, minWidth: 380, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                  <FromTo from={from} to={to} fromDays={fromDays} toDays={toDays} refreshData={refreshData} variant={variant} subVariant={subVariant} />
                </div>
              </div>
            ),
            Container: props => <Paper {...props} elevation={0} />
          }}
        />
      </Grid>
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

export default connect(mapStateToProps)(PoliciesTableNew);
